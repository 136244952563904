import {
  FaBlog,
  FaGithub,
  FaLinkedin,
  FaEnvelope,
  FaPodcast,
  FaTwitter,
} from "react-icons/fa";

export const links = [
  {
    index: 0,
    title: "Find me on Github",
    href: "https://github.com/torkralle",
    icon: <FaGithub />,
  },
  {
    index: 1,
    title: "Find me on LinkedIn",
    href: "https://www.linkedin.com/in/hoshiko/",
    icon: <FaLinkedin />,
  },
  {
    index: 2,
    title: "Find me on Twitter",
    href: "https://twitter.com/Hoshiko99",
    icon: <FaTwitter />,
  },
  {
    index: 3,
    title: "Find me on Note",
    href: "https://note.com/torkralle",
    icon: <FaBlog />,
  },
  {
    index: 4,
    title: "Find me on Stand.fm",
    href: "https://stand.fm/channels/63d3952a70af05f9d175ddb2",
    icon: <FaPodcast />,
  },
];
