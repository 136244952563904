export const pages = [
  {
    index: 0,
    name: "overview.md",
    route: "english/overview.md",
    folderName: "aboutMe",
  },
  {
    index: 1,
    name: "skills.md",
    route: "english/skills.md",
    folderName: "aboutMe",
  },
  {
    index: 2,
    name: "experience.md",
    route: "english/experience.md",
    folderName: "aboutMe",
  },
  {
    index: 3,
    name: "education.md",
    route: "english/education.md",
    folderName: "aboutMe",
  },
  {
    index: 4,
    name: "projects.md",
    route: "english/projects.md",
    folderName: "aboutMe",
  },
  {
    index: 5,
    name: "certificates.md",
    route: "english/certificates.md",
    folderName: "aboutMe",
  },
  {
    index: 6,
    name: "accomplishments.md",
    route: "english/accomplishments.md",
    folderName: "aboutMe",
  },
  {
    index: 7,
    name: "hobbies.md",
    route: "english/hobbies.md",
    folderName: "aboutMe",
  },
  // // {
  //   index: 8,
  //   name: "overview.md",
  //   route: "japanese/overview.md",
  //   folderName: "aboutMeJa",
  // },
  {
    index: 8,
    name: "skills.md",
    route: "japanese/skills.md",
    folderName: "aboutMeJa",
  },
  {
    index: 9,
    name: "experience.md",
    route: "japanese/experience.md",
    folderName: "aboutMeJa",
  },
  {
    index: 10,
    name: "education.md",
    route: "japanese/education.md",
    folderName: "aboutMeJa",
  },
  {
    index: 11,
    name: "projects.md",
    route: "japanese/projects.md",
    folderName: "aboutMeJa",
  },
  {
    index: 12,
    name: "certificates.md",
    route: "japanese/certificates.md",
    folderName: "aboutMeJa",
  },
  {
    index: 13,
    name: "hobbies.md",
    route: "japanese/hobbies.md",
    folderName: "aboutMeJa",
  },
];
